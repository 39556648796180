









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import _ from 'lodash';

@Component({
    components: { CustomSelect },
})
export default class CarProviderFilter extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    get items(): Item[] {
        const { providers } = this.sippService.filters;
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;

        if (!providers) {
            return [{ name: 'Any', value: 'Any' }];
        }

        if (!this.currentValue || !providers.find(carClass => carClass === this.currentValue)) {
            const [defaultPos] = providers;
            this.currentValue = defaultPos;
        }
        return [
            ...providers.map(value => ({
                name: _.get(carProvidersDisplayNamesMap, value) || value,
                value,
            })),
        ];
    }

    get currentValue() {
        return this.sippSettingsService.providersValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.providersValue = value;
        }
    }
}
