






import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: { StyledSearchbar },
})
export default class SippCarNameFilter extends Vue {
    @Prop({
        type: Function,
        required: true,
    })
    afterType!: (query: string | null) => void;

    @Prop({
        type: String,
        required: false,
    })
    defaultValue?: string;
}
