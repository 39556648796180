















import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class CarClassesFilter extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    @Watch('sippSettingsService.storeState.isFilterReseted', { deep: true })
    onResetFilters() {
        this.handleChange('');
    }

    private query: string | null = null;

    get items(): Item[] {
        let { carClasses } = this.sippService.filters;

        if (!carClasses) {
            return [{ name: 'Any', value: 'Any' }];
        }

        if (!this.currentValue || !carClasses.find(carClass => carClass === this.currentValue)) {
            const [defaultPos] = carClasses;
            this.currentValue = defaultPos;
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());

            carClasses = carClasses?.filter(item => item === 'Any' || searchPattern.test(item.toLowerCase()));
        }

        return carClasses.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.sippSettingsService.carClassesValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.carClassesValue = value;
        }
    }

    handleChange(query: string) {
        this.query = query;
        this.$emit('input', query);
    }
}
