









import { Vue } from 'vue-property-decorator';

export default class CarNote extends Vue {

}

