









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import { POS_ANY } from '@/modules/cars/constants/car-filter-types.constant';

@Component({
    components: { CustomSelect },
})
export default class PosFilter extends Vue {
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    get items(): Item[] {
        const { pos } = this.sippSettingsService.storeState.filters;

        return [POS_ANY, ...(pos || [])].reduce((acc: Item[], value: string) => {
            acc.push({ value, name: value });

            return acc;
        }, []);
    }

    get currentValue() {
        return this.sippSettingsService.posValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.posValue = value;
        }
    }
}
