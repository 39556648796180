




import { Component, Vue } from 'vue-property-decorator';
import Sipp from '@/modules/cars/modules/sipp/components/sipp.vue';

@Component({
    components: {
        Sipp,
    },
})
export default class SippModalPage extends Vue {}
