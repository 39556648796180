















import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import { CAR_CLASS_ANY, CAR_CLASS_BLANK, POS_ANY } from '@/modules/cars/constants/car-filter-types.constant';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class CarNormalizedClassesFilter extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    @Watch('sippSettingsService.storeState.isFilterReseted', { deep: true })
    onResetFilters() {
        this.handleChange('');
    }

    private query: string | null = null;

    get items(): Item[] {
        const { normalizedCarCategory, normalizedCarCategoryPerPos, pos } = this.sippService.filters;

        if (!normalizedCarCategory?.length) {
            return [{ name: CAR_CLASS_ANY, value: CAR_CLASS_ANY }];
        }

        let sortedNormalizedCarCategory = normalizedCarCategory?.sort((a, b) => a.localeCompare(b));

        if (
            this.sippService.currentPos
            && this.sippService.currentPos !== POS_ANY
            && normalizedCarCategoryPerPos?.[this.sippService.currentPos]
        ) {
            sortedNormalizedCarCategory = normalizedCarCategoryPerPos[this.sippService.currentPos].filter(item => item).sort((a, b) => a.localeCompare(b));
        }

        let filteredValues = [CAR_CLASS_ANY, CAR_CLASS_BLANK, ...sortedNormalizedCarCategory];

        if (!this.currentValue || !filteredValues?.find(normalizedCarCategory => normalizedCarCategory === this.currentValue)) {
            const [defaultPos] = filteredValues;
            this.currentValue = defaultPos;
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());

            filteredValues = filteredValues?.filter(item => item === CAR_CLASS_ANY || searchPattern.test(item.toLowerCase()));
        }

        return [
            ...(filteredValues ? [...filteredValues] : []),
        ].map(item => ({
            value: item,
            name: item,
        }));
    }

    get currentValue() {
        return this.sippSettingsService.carNormalizedClassesValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.carNormalizedClassesValue = value;
        }
    }

    handleChange(query: string) {
        this.query = query;
        this.$emit('input', query);
    }
}
