

































































import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { ISippTableHead } from '@/modules/cars/modules/sipp/components/sipp.vue';
import { Inject } from 'inversify-props';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippDiffModel from '@/modules/cars/modules/sipp/models/sipp-diff.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import { Dialog } from 'element-ui';
import _ from 'lodash';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';
import AddCarCategoryPopup from '@/modules/cars/modules/sipp/components/add-car-category.popup.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SippItemModel from '../models/sipp-item.model';

export interface ISippChangeData {
    customerSippCode: string | null;
    categoryName: string | null;
    vehicleType: string | null;
    fuelType: string | null;
}
@Component({
    components: {
        CustomSelect,
        Dialog,
        AddCarCategoryPopup,
    },
})
export default class SippItem extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    @Prop({
        type: Object,
        default: null,
    })
    item!: SippItemModel;

    @Prop({
        type: Array,
        required: true,
    })
    columns!: ISippTableHead[];

    @Prop({
        type: Number,
        required: true,
    })
    index!: number;

    @Prop({
        type: Boolean,
        required: true,
        default: false,
    })
    isHistoryOpen!: boolean;

    @Prop({
        type: String,
        required: true,
    })
    hoverColumn!: string;

    @Prop({
        type: Function,
        required: true,
    })
    focusNext!: Function;

    @Prop({
        type: Function,
        required: true,
    })
    focusPrev!: Function;

    @Prop({
        type: Boolean,
        required: true,
        default: true,
    })
    isReadonly!: Boolean;

    get key() {
        return this.hoverColumn;
    }

    isHover(key: string) {
        if (!key) {
            return false;
        }
        return key === this.key;
    }

    changedData: ISippChangeData = {
        customerSippCode: this.item.itemDiff?.customerSippCode || this.item.customerSippCode,
        categoryName: this.item.itemDiff?.categoryName || this.item.categoryName,
        vehicleType: this.item.itemDiff?.vehicleType || this.item.vehicleType,
        fuelType: this.item.itemDiff?.fuelType || this.item.fuelType,
    };

    private modifyColumns = ['customerSippCode', 'categoryName', 'fuelType', 'vehicleType'];
    private visible: boolean = false;
    public isInvalid: boolean = false;
    public currentIndex: number = 0;
    public isInvalidCustomerSippCode: boolean = false;

    close() {
        this.visible = !this.visible;
    }

    change(data: { name: string }) {
        this.visible = !this.visible;
        this.changedData.categoryName = data.name;
        this.sippService.newCategories = [this.changedData.categoryName];
    }

    highlight(key: string) {
        return (this.isInHistoryLog || this.isInPreview) && this.modifyColumns.includes(key) && this.isChanged(key);
    }

    isChanged(key: string) {
        if (!this.sippService.items) {
            return false;
        }
        const item: SippItemModel = this.sippService.items[this.index];
        // @ts-ignore
        if (item[key] === this.changedData[key]) {
            return false;
        }

        return true;
    }

    valueByKey(key: string) {
        if (key === 'categoryName') {
            return this.changedData.categoryName;
        }

        if (key === 'customerSippCode') {
            return this.changedData.customerSippCode;
        }

        if (key === 'fuelType') {
            return this.changedData.fuelType;
        }

        if (key === 'vehicleType') {
            // currently support just 'VAN'
            const { vehicleTypes } = this.sippService.filters;
            if (vehicleTypes) {
                const van = vehicleTypes[1];
                return (van === this.changedData.vehicleType) ? 'Yes' : 'No';
            }
        }

        if (!this.item) {
            return '';
        }
        // @ts-ignore
        return this.item[key];
    }

    get isVisible() {
        return this.visible;
    }

    get isInHistoryLog() {
        const changes = this.sippService.logsChanges;
        if (!changes) {
            return false;
        }
        const foundChange = changes.find(item => item.id === this.item.id);
        if (foundChange === undefined) {
            return false;
        }
        return true;
    }

    get isInPreview() {
        const changes = this.sippService.documentsPreview;
        if (!changes) {
            return false;
        }
        const foundChange = changes.find(item => item.id === this.item.id);
        if (foundChange === undefined) {
            return false;
        }
        return true;
    }

    handleChange(query: ISippChangeData) {
        const {
            categoryName,
            customerSippCode,
            fuelType,
            vehicleType,
        } = query;

        const sippDiff: SippDiffModel = {
            _id: this.item.id,
            fn_sipp_code: this.item.fnSippCode,
            current_customer_sipp_code: this.item.customerSippCode || '',
            new_customer_sipp_code: customerSippCode || '',
            current_customer_normalized_category: this.item.categoryName || '',
            new_customer_normalized_category: categoryName,
            current_vehicle_type: this.item.vehicleType,
            new_vehicle_type: vehicleType,
            current_fuel_type: this.item.fuelType,
            new_fuel_type: fuelType,
        };

        if (!sippDiff.new_customer_normalized_category) {
            this.isInvalid = true;
        } else {
            this.isInvalid = false;
        }

        this.sippService.changedSippCode(sippDiff);
    }

    mounted() {
        this.validateCustomerSippCode(this.changedData.customerSippCode);
    }

    @Watch('changedData', { deep: true })
    typing() {
        this.validateCustomerSippCode(this.changedData.customerSippCode);
        this.handleChange(this.changedData);
    }

    @Watch('item.customerSippCode')
    setCustom() {
        if (this.item.customerSippCode !== this.changedData.customerSippCode) {
            this.changedData.customerSippCode = this.item.customerSippCode;
        }
    }

    @Watch('item.categoryName')
    setCategoryName() {
        if (this.item.categoryName !== this.changedData.categoryName) {
            this.changedData.categoryName = this.item.categoryName;
        }
    }

    @Watch('item.vehicleType')
    setVehicleType() {
        if (this.item.vehicleType !== this.changedData.vehicleType) {
            this.changedData.vehicleType = this.item.vehicleType;
        }
    }

    @Watch('item.fuelType')
    setFuelType() {
        if (this.item.fuelType !== this.changedData.fuelType) {
            this.changedData.fuelType = this.item.fuelType;
        }
    }

    get isAdmin() {
        return this.userService.isAdmin;
    }

    get updateTitle() {
        return !this.isReadonly ? 'Allowed only 4-6 alphabetical characters or empty.' : '';
    }

    get items(): Item[] {
        const { normalizedCarCategory } = this.sippService.filters;
        const { newCategories } = this.sippService;

        if (!normalizedCarCategory) {
            return [];
        }
        let concatCategories = [...normalizedCarCategory, ...newCategories];
        concatCategories = [...new Set(concatCategories)];
        return concatCategories.map(value => ({
            name: value,
            value,
        }));
    }

    get vehicleItems(): Item[] {
        const { vehicleTypes } = this.sippService.filters;

        if (!vehicleTypes) {
            return [];
        }

        // currently support just 'VAN'
        const van = vehicleTypes[1]; // 'VAN'
        return [
            { name: 'Yes', value: van },
            { name: 'No', value: '' },
        ];
    }

    get currentValue() {
        return this.item.categoryName;
    }

    get fuelTypeItems(): Item[] {
        return CAR_FUEL_TYPE.map(type => (
            { name: type, value: type }
        ));
    }

    validateCustomerSippCode(value: string | null): boolean {
        if (!value) {
            this.isInvalidCustomerSippCode = false;
            this.$set(this.sippService.storeState.invalidItems, this.item.id, false);
            return true;
        }

        const regex = /^[a-zA-Z]{4,6}$/;
        const isValid = regex.test(value);
        this.isInvalidCustomerSippCode = !isValid;
        this.$set(this.sippService.storeState.invalidItems, this.item.id, !isValid);

        return isValid;
    }

    moveNext(event: Event) {
        if (this.focusNext) {
            this.focusNext(event);
        }
    }
    movePrev(event: Event) {
        if (this.focusPrev) {
            this.focusPrev(event);
        }
    }
}
