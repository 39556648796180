















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import VEHICLE_TYPE_FILTER from '@/modules/cars/constants/vehicle-type-filter.constant';
import CarsService, { CarsServiceS } from '../cars.service';

@Component
export default class CarsSwitcher extends Vue {
    @Inject(CarsServiceS) carsService!: CarsService;

    @Prop({
        type: String,
    })
    private valueSwitcher!: string;

    created() {
        if (this.valueSwitcher === 'isVansSippCodePopup') {
            (this.carsService as any).storeState.settings[this.valueSwitcher] = (this.carsService as any).storeState.settings.isVans;
        }
    }

    get isVans(): boolean {
        return (this.carsService as any).storeState.settings[this.valueSwitcher];
    }

    public onClick(value: boolean) {
        (this.carsService as any).storeState.settings[this.valueSwitcher] = value;

        this.carsService.storeState.settings.vehicleType.filter = value ? VEHICLE_TYPE_FILTER.INCLUDE : VEHICLE_TYPE_FILTER.WITHOUT;
    }
}
