










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import _ from 'lodash';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component({
    components: { CustomSelect },
})
export default class CountryFilter extends Vue {
    @Inject(SippSettingsServiceS) private sippSettingsServie!: SippSettingsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    get items(): Item[] {
        const { countryName } = this.sippSettingsServie.storeState.filters;
        const { countryCodes } = this.carsFiltersService;

        const resultedCountryName = countryCodes?.filter(item => countryName?.includes(item.name))
            .map(item => ({
                name: item.name,
                code: item.code,
            })) ?? [];

        const countries = [
            {
                name: COUNTRIES_ANY,
                code: COUNTRIES_ANY,
            },
            ...(resultedCountryName && resultedCountryName.length ? _.sortBy(resultedCountryName, ['code']) : []),
        ];

        return countries.map(value => ({
            name: String(value.code),
            value: value.name,
        }));
    }

    get currentValue() {
        return this.sippSettingsServie.countryValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsServie.countryValue = value;
        }
    }
}
