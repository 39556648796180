









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';

@Component({
    components: { CustomSelect },
})
export default class TransmissionFilter extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    get items(): Item[] {
        const { transmission } = this.sippService.filters;

        if (!transmission) {
            return [{ name: 'Any', value: 'Any' }];
        }

        return transmission.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        return this.sippSettingsService.transmissionValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.transmissionValue = value;
        }
    }
}
